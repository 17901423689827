body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #151719!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.content {
  /* margin-top: 40.1px; */
  display: block;
  background-color: #151719;
}

.post-footer {
  /* margin-top: 40.1px; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  background-color: #151719;
  color: #0f0;
  font-size: 22px;
  vertical-align: 0px;
}
.post-footer a {
  color: #0f0;
  font-size: 18px;
  background-color: #202224!important;
}
.post-footer span {
  vertical-align: 0px;
}

.post-footer ul {
  width: 70%;
  margin: auto;
  align-content: space-evenly;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  padding-bottom: 20px;
  padding-top: 20px;
}
  
.ab12 a {
  outline: none;
  box-shadow: none;
}

.btn:focus a {
  outline: none;
  box-shadow: none;
}